import { useEffect, useRef, useState } from "react";
import config from "config/main-config.json";
import "./Booking.scss";
import {
    useScript,
    scriptMapper,
    useResovaVoucherScript,
    useForEscapeScript,
} from "helpers/Booking.helper";
import { useParams } from "react-router";
import ResovaFooter from "./ResovaFooter";
import ResovaIframe from "components/ResovaIFrame/ResovaIFrame";
import { testIds } from "enums/testIds";

const Booking = () => {
    const { type, storeName } = useParams();
    const store = config.stores.find((store) => store.name === storeName);
    const sectionRef = useRef(null);
    const [reloadExecuted, setReloadExecuted] = useState(false);
    const scriptUrl = scriptMapper(type, store);
    const [iframeHeight, setIframeHeight] = useState("100px");
    const isResovaUrl = scriptUrl?.includes("resova");
    const isLisbonLastChanceTeamTwo = type === "last-chance-lisbon-team-two";

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);

        return () => {
            window.removeEventListener("message", receiveMessage);
        };
    }, []);

    function receiveMessage(event) {
        let data;
        try {
            data = JSON.parse(event.data);
        } catch (error) {
            if (data !== undefined) {
                // Only log the error if there is data that failed to parse
                // Without data, its an expected error and we don't need to log it.
                console.error("Error parsing message data:", error);
            }
            return;
        }

        if (data?.readjust_height) {
            const newHeight = data.readjust_height;
            setIframeHeight(newHeight);
        }
    }

    const reloadOnce = () => {
        if (!reloadExecuted && sectionRef?.current?.children?.length === 1) {
            setReloadExecuted(true);
            if (
                type === "gift_vouchers" ||
                type === "redeem_vouchers" ||
                type === "booking"
            ) {
                window.location.reload();
            }
        }
    };

    useEffect(() => {
        const scriptFunctions = {
            gift_vouchers: () => useScript(scriptUrl, sectionRef),
            redeem_vouchers: () => useScript(scriptUrl, sectionRef),
            booking: () => useScript(scriptUrl, sectionRef),
            resova_vouchers: () =>
                useResovaVoucherScript(scriptUrl, sectionRef, storeName),
            "4escape_booking": () =>
                useForEscapeScript(scriptUrl, sectionRef, storeName, type),
            "4escape_vouchers": () =>
                useForEscapeScript(scriptUrl, sectionRef, storeName, type),
        };

        const scriptFunction = scriptFunctions[type];
        if (scriptFunction) {
            scriptFunction();
        }
    }, [type, store]);

    useEffect(() => {
        const intervalId = setInterval(reloadOnce, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const resovaIframe = document.getElementById("resova-iframe");
    if (resovaIframe) {
        resovaIframe.height = iframeHeight;
    }

    return (
        <>
            <section
                ref={sectionRef}
                className={`content-section the_external_content_of_${config.stores[0].name}`}
                data-testid={testIds.booking_section}>
                {isLisbonLastChanceTeamTwo && (
                    <div className='flex flex-col justify-center items-center'>
                        <ResovaIframe
                            src={config.last_chance_team_booking_url_lisbon}
                        />
                    </div>
                )}
            </section>
            {isResovaUrl && <ResovaFooter />}
        </>
    );
};

export default Booking;
